import { createContext, useContext, useEffect, useReducer, useState } from "react"
import useSWR from "swr"
import { fetcher } from "../utils/fetcher"

export const WidgetContext = createContext({
  widget: null
})

export const StateContext = createContext({
  uiState: null,
  setUiState: null,
  queryState: null,
  setQueryState: null,
})

export default function StateProvider({ children, widgetId, widgetEnv }) {

  // Define initial state
  const initUiState = {
    filterMenuOpen: false,
    currentEvent: null,
    currentFilterCount: 0,
    userSearchText: "",
    locationDisplay: "Everywhere",
    locationSearchTerms: "",
  }

  const initQueryState = {
    // Widget data
    hostId: null,
    apiKey: null,
    // Current query state
    widgetSearchText: "",
    text: "",
    location: null,
    radius: null,
    locationData: null,
    ridingStyles: [],
    eventTypes: [],
    priceMin: 1,
    priceMax: 6,
    sizeMin: 1,
    sizeMax: 5,
    price: 6,
    size: 5,
    sort: "end:asc",
    page: 1,
    pageSize: 12,
  }

  // Get environment from either url or node env
  const getEnvironment = () => {
    const nodeEnv = process.env.REACT_APP_ENV

    if(widgetEnv) {
      switch (widgetEnv) {
        case "preview":
          return ""
        default:
          break;
      }
    }

    switch (nodeEnv) {
      case "development":
        return "http://localhost:3000"
      case "testing":
        return "https://testing.kickstandsup.com"
      case "production":
        return "https://www.kickstandsup.com"
      case "preview":
        return ""
      default:
        break;
    }
  }

  // State
  const environment = getEnvironment()
  const [ uiState, setUiState ] = useReducer((state, newState) => ({ ...state, ...newState }), initUiState)
  const [ queryState, setQueryState ] = useReducer((state, newState) => ({ ...state, ...newState }), initQueryState)
  const { data: widget, error: widgetError } = useSWR([`${environment}/api/widgets/fetch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ id: widgetId })
  }], fetcher)
  const { data: eventResults, error: eventResultsError } = useSWR([`${environment}/api/events/fetch-events`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(queryState)
  }], fetcher)

  // Build search term presets (invisible to user, set by host)
  const searchTermPresets = () => {
    const searchTerms = []

    if(widget.includeTerms && widget.includeTerms.length > 0) {
      widget.includeTerms.forEach(term => {
        searchTerms.push(term.name)
      })
    }

    if(widget.excludeTerms && widget.excludeTerms.length > 0) {
      widget.excludeTerms.forEach(term => {
        searchTerms.push(`-${term.name}`)
      })
    }

    if(widget.type === "host") {
      searchTerms.push(`${widget.hostId}`)
    }

    return searchTerms.join(" ")
  }

  // Set state from fetched widget data
  useEffect(() => {
    if(widget) {
      console.log(widget)
      const presets = searchTermPresets()

      setQueryState({
        ...queryState,
        hostId: widget.hostId,
        apiKey: widget.apiKey,
        location: widget.location,
        radius: widget.radius,
        locationData: widget.locationData,
        ridingStyles: widget.ridingStyles,
        eventTypes: widget.eventTypes,
        priceMin: widget.priceMin,
        priceMax: widget.priceMax,
        sizeMin: widget.sizeMin,
        sizeMax: widget.sizeMax,
        widgetSearchText: presets,
        text: `${uiState.userSearchText} ${presets}`,
      })

      setUiState({
        ...uiState,
        locationDisplay: widget.locationData?.description && widget.radius?.name ? `${widget.radius.name} of ${widget.locationData.description}` : "Everywhere",
        locationSearchTerms: widget.locationData?.description ? widget.locationData.description : "",
      })
    }
  }, [widget])

  // Update state when user changes search text
  useEffect(() => {
    setQueryState({
      ...queryState,
      text: `${uiState.userSearchText} ${queryState.widgetSearchText}`
    })
  }, [uiState.userSearchText])

  // Update location display when location changes
  useEffect(() => {
    if(queryState.locationData) {
      setUiState({
        ...uiState,
        locationDisplay: queryState.locationData?.description && queryState.radius?.name ? `${queryState.radius.name} of ${queryState.locationData.description}` : "Everywhere",
      })
    }
  }, [queryState.locationData])


  // // Log state changes
  // useEffect(() => console.log(uiState), [uiState])

  // // Log events
  // useEffect(() => console.log(eventResults), [eventResults])

  // // Log widget
  // useEffect(() => console.log(widget), [widget])

  const clearQuery = () => {
    const presets = searchTermPresets()

    setQueryState({
      ...initQueryState,
      hostId: widget.hostId,
      apiKey: widget.apiKey,
      location: widget.location,
      radius: widget.radius,
      locationData: widget.locationData,
      ridingStyles: widget.ridingStyles,
      eventTypes: widget.eventTypes,
      priceMin: widget.priceMin,
      priceMax: widget.priceMax,
      sizeMin: widget.sizeMin,
      sizeMax: widget.sizeMax,
      widgetSearchText: presets,
      text: `${presets}`,
    })

    setUiState({
      ...uiState,
      locationDisplay: widget.locationData?.description && widget.radius?.name ? `${widget.radius.name} of ${widget.locationData.description}` : "Everywhere",
      locationSearchTerms: widget.locationData?.description ? widget.locationData.description : "",
    })
  }


  return (
    <WidgetContext.Provider value={{widget, widgetError}}>
      <StateContext.Provider value={{environment, queryState, setQueryState, clearQuery, uiState, setUiState, eventResults, eventResultsError, widgetEnv}}>
        {children}
      </StateContext.Provider>
    </WidgetContext.Provider>
  )
}

export const useWidget = () => {
  return useContext(WidgetContext)
}

export const useWidgetState = () => {
  return useContext(StateContext)
}


