import { useWidgetState } from "../providers/StateProvider"
import WidgetEventCard from "./WidgetEventCard"
import EventCardSkeleton from "./WidgetEventCard/EventCardSkeleton"
import { AnimatePresence } from "framer-motion"

export default function WidgetEvents() {
  const { eventResults, eventResultsError } = useWidgetState()

  console.log(eventResults, 'WidgetEvents.jsx: events')

  return(
    <>
      {!eventResults?.message?.includes("ERROR") &&
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-6">
            {eventResults && eventResults?.events && eventResults?.events?.length > 0 &&
              <AnimatePresence>
                {eventResults.events.map((event, index) => {
                  return(
                    <WidgetEventCard key={index} event={event}/>
                  )
                })}
              </AnimatePresence>
            }
            {!eventResults &&
              <AnimatePresence popLayout>
                {Array(12).fill("").map(() =>
                  <EventCardSkeleton key={`skeleton-event-card-${Math.random() * Math.random()}`} feed={true}/>
                )}
              </AnimatePresence>
            }
          </div>
          {eventResults && eventResults?.events.length === 0 &&
            <div className="text-center">
              <h2>No events found!</h2>
              <p>Try widening your search.</p>
            </div>
          }
        </>
      }
      {(eventResultsError || eventResults?.message?.includes("ERROR")) &&
        <div className="text-center">
          <h2>Error loading events.</h2>
          <p>Please contact site support.</p>
        </div>
      }
    </>
  )
}