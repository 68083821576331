import bikeTypes from "../../config/bikeTypes"
import Checkbox from "../Input/Checkbox"
import { envPath } from "../../utils/envPath"

export default function FilterRidingStyles({ widget, query, setQuery }) {
  const handleRidingStyles = async (ridingStyle) => {
    const ridingStyles = query.ridingStyles

    if(ridingStyles.some(style => style === ridingStyle)) {
      setQuery({...query, "ridingStyles": ridingStyles.filter(style => style !== ridingStyle), "page": 1})
    } else {
      setQuery({...query, "ridingStyles": [...ridingStyles, ridingStyle], "page": 1})
    }
  }

  const ridingStyleIsChecked = (style) => {
    return query.ridingStyles.includes(style)
  }

  return(
    <>
      {bikeTypes.map(bike => {
        if(widget.ridingStyles.includes(bike.title)) {
          return(
            <Checkbox
              key={bike.slug}
              id={bike.slug}
              checked={ridingStyleIsChecked(bike.title)}
              callback={() => handleRidingStyles(bike.title)}
              label={
                <div className="flex items-center">
                  <img src={`${envPath()}/assets/bikes/${bike.slug}.svg`} alt={`${bike.name} Icon`} width="40" height="40" />
                  <span className="ml-4">{bike.title}</span>
                </div>
              }
            />
          )
        }
      })}
    </>
  )
}