import React from "react"
import WidgetHeader from "./WidgetHeader"
import WidgetFilterMenu from "./WidgetFilterMenu"
import { useWidget, useWidgetState } from "../providers/StateProvider"
import WidgetEvents from "./WidgetEvents"
import WidgetPagination from "./WidgetPagination"
import WidgetPoweredBy from "./WidgetPoweredBy"
import WidgetEventDrawer from "./WidgetEventDrawer"
import { AnimatePresence, motion } from "framer-motion"
import { envPath } from "../utils/envPath"

export default function EmbeddableWidget() {
  const { widget, widgetError } = useWidget()
  const { uiState } = useWidgetState()
  const { currentEvent } = uiState

  const viewAnimations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return(
    <div className="relative mx-auto w-full min-h-[500px] bg-off-white" id="KsuWidgetMain">
      <WidgetPoweredBy/>
      <AnimatePresence>
        {widget &&
          <motion.div initial="initial" animate="animate" exit="exit" variants={viewAnimations} className="flex flex-col flex-grow w-full h-full min-h-[1000px]">
            <WidgetHeader/>
            <section className="p-4 lg:p-8 h-full">
              <WidgetEvents/>
              <WidgetPagination/>
              <AnimatePresence>
                {currentEvent &&
                  <WidgetEventDrawer/>
                }
              </AnimatePresence>
            </section>
            <WidgetFilterMenu/>
          </motion.div>
        }
      </AnimatePresence>
      <AnimatePresence>
        {!widget && !widgetError &&
          <motion.div initial="initial" animate="animate" exit="exit" variants={viewAnimations} className="p-16 h-full min-h-[500px] bg-black flex items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              <div className="p-4 animation-flip">
                <img src={`${envPath()}/assets/logo-icon.png`} className="w-16"/>
              </div>
              <span className="m-0 font-bold text-5xl text-white font-barlow">LOADING</span>
            </div>
          </motion.div>
        }
      </AnimatePresence>
      <AnimatePresence>
        {!widget && widgetError &&
          <motion.div initial="initial" animate="animate" exit="exit" variants={viewAnimations} className="p-16 h-full">
            <h1 className="m-0 font-bold">ERROR</h1>
            <p>Widget not found.</p>
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}