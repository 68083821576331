import { useWidgetState } from "../providers/StateProvider"
import { AnimatePresence, motion } from "framer-motion"
import { FaTimes } from "react-icons/fa"
import EventDateTimeCard from "./WidgetEventCard/EventDateTimeCard"
import EventCostIcons from "./WidgetEventCard/EventCostIcons"
import EventAttendanceIcons from "./WidgetEventCard/EventAttendanceIcons"
import { envPath } from "../utils/envPath"

export default function WidgetEventDrawer() {
  const { environment, uiState, setUiState } = useWidgetState()
  const { currentEvent } = uiState

  const overlayAnimations = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    }
  }

  const drawerAnimations = {
    initial: {
      x: "100%",
    },
    animate: {
      x: 0,
    },
    exit: {
      x: "100%",
    }
  }

  return(
    <motion.div initial={overlayAnimations.initial} animate={overlayAnimations.animate} exit={overlayAnimations.exit} className="fixed z-[999] top-0 right-0 w-screen h-screen bg-black-opaque-dark bg-opacity-50">
      <AnimatePresence>
        {currentEvent &&
          <motion.div initial={drawerAnimations.initial} animate={drawerAnimations.animate} exit={drawerAnimations.exit} className="relative ml-auto z-50 w-full h-screen max-w-[780px] bg-off-black">
            <div className="bg-off-black flex justify-end text-white flex">
              <button onClick={() => setUiState({...uiState, currentEvent: null})} className="p-4 text-xl flex items-center font-barlow font-bold"><FaTimes className="mr-2"/> CLOSE</button>
            </div>
            <div className="relative h-full">
              <div className="aspect-video w-full bg-shimmer">
                <img src={currentEvent.eventImages.bannerImg} alt={currentEvent.eventName} className="w-full h-full object-cover object-center"  placeholder="blur" />
              </div>
              <div className="w-full bg-darkest-grey text-white uppercase font-barlow grid grid-flow-col">
                {/* <Link href={`/events/${currentEvent.eventSlug}`}>
                  <a className="button button-tertiary rounded-none flex items-center"><EyeIcon className="mr-2"/> View Event</a>
                </Link> */}
              </div>
              <div className="p-8 bg-white">
                <p className="pb-2 uppercase font-barlow text-2xl"><strong>{currentEvent.eventName}</strong></p>
                {currentEvent.eventLocationDescription &&
                  <p className="pb-2 text-sm">{currentEvent.eventLocationDescription ? currentEvent.eventLocationDescription.replace(", USA", "") : ""}</p>
                }
                <div className="mb-2 flex items-center text-dark-grey">
                  <EventCostIcons free={currentEvent.free} price={currentEvent.price} priceMin={currentEvent.priceMin} classes="-mt-0.5 text-base mr-2" iconClasses="" />
                  <EventAttendanceIcons size={currentEvent.eventSize} classes="text-lg ml-3" iconClasses="-ml-2" />
                </div>
                <EventDateTimeCard start={currentEvent.start} end={currentEvent.end} timezone={currentEvent.timezone} />
                <div className="text-sm flex flex-col">
                  <span>Host: <strong className="ml-2">{currentEvent.hostName}</strong></span>
                  {currentEvent.cohostName &&
                    <span>Co-Host: <strong className="ml-2">{currentEvent.cohostName}</strong></span>
                  }
                </div>
              </div>
              <a className="button button-tertiary rounded-none font-bold text-xl" href={`${environment}/events/${currentEvent.eventSlug}`} target="_blank">
                <img className="w-4 mr-2" src={`${envPath()}/assets/logo-icon.png`} />
                <span>View More On KickstandsUp</span>
              </a>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </motion.div>
  )
}